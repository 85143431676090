'use strict';
import 'fslightbox';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/alert';

import { hideSpinner, lazyLoadIntersectionObserver, showToast, setListManagerButtonIcon, hideModal, monitorCarouselDrags } from './helperFunctions.js';
import { GATEWAY_DOMAIN } from './constants.js';
import { buildWatchlistButton } from './helpers/buildWatchlistButton.js';

// eslint-disable-next-line no-unused-vars
/* global isAdmin, combinedUserID, isLoggedIn, navbarHidden, userType, formKey, returnUrl, WATCHMODE_CDN1,  listID */
/* global clients */

var globalCombinedTitleID = '';
// Catch any JS errors and send their details to the server
window.onerror = (msg, source, lineNo, columnNo, error) => {
    const data = new FormData();
    data.set('appPlatform', 'web');
    data.set('message', msg);
    data.set('source', source);
    data.set('line', lineNo);
    data.set('column', columnNo);
    data.set('error', error);
    data.set('href', window.location.href);

    fetch(GATEWAY_DOMAIN + '/errorLogJs/', {
        method: 'POST',
        credentials: 'include',
        body: data
    });
};

window.onload = () => {
    hideSpinner();
    // Initiate the lazy load image observer
    lazyLoadIntersectionObserver();
    toggleHiddenSearchIcon();
    monitorCarouselDrags();
    setupSearchInputs();
};

document.addEventListener('click', (event) => {
    // Hide any open search containers on click
    const searchInputs = document.querySelectorAll('.jsTypeaheadInput');
    [].forEach.call(searchInputs, (searchInput) => {
        var resultsContainer = document.querySelector('#' + searchInput.dataset.container);
        resultsContainer.classList.add('d-none');
        resultsContainer.classList.remove('d-block');
    });
    if (event.target.closest('.toggleSearchDropdown')) {
        const searchDrop = document.getElementById('searchDropdown');
        if (searchDrop.classList.contains('d-none')) {
            searchDrop.classList.remove('d-none');
            document.body.classList.add('modal-open');
        } else {
            searchDrop.classList.add('d-none');
            document.body.classList.remove('modal-open');
        }
    }

    if (event.target.id === 'resendConfirmationLink') {
        const data = new FormData();
        data.set('appPlatform', 'web');
        data.set('method', 'resendConfirmationLink');
        fetch(GATEWAY_DOMAIN + '/userActions/', {
            method: 'POST',
            credentials: 'include',
            body: data
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    showToast('Confirmation email sent!');
                } else {
                    showToast(data.errorMessage);
                }
            });
    }

    // Close button in mobile nav overlay
    if (event.target.id == 'mobileNavClose') {
        const navbarCollapse = document.querySelector('.navbar-collapse');
        navbarCollapse.classList.remove('show');
        document.body.classList.remove('offcanvas-active');
    }

    if (event.target.closest('.disabledLink') || event.target.classList.contains('disabledLink')) {
        event.preventDefault();
    }

    // Close the responsive menu when a link is pressed
    if (event.target.matches('.nav-link') && !event.target.matches('.dropdown-toggle')) {
        const navbarCollapse = document.querySelector('.navbar-collapse');
        navbarCollapse.classList.remove('show');
        document.body.classList.remove('offcanvas-active');
    }

    // Handle when a user presses X to remove an alert message
    if (event.target.id == 'dismissSystemMsg') {
        event.preventDefault();
        const data = new FormData();
        data.set('appPlatform', 'web');
        data.set('method', 'dismissMessage');
        data.set('messageID', document.getElementById('dismissSystemMsg').dataset.messageid);

        fetch(GATEWAY_DOMAIN + '/userActions/', {
            method: 'POST',
            credentials: 'include',
            body: data
        }).then((response) => {
            const alertContainer = document.getElementById('alertContainer');
            alertContainer.classList.remove('show');
            alertContainer.classList.add('hide');
            setTimeout(() => {
                alertContainer.remove();
            }, 400);
            return response.json();
        });
    }

    // Fade out a toast on click
    if (event.target.id == 'toast') event.target.className = event.target.className.replace('show', '');

    // handle the press of a notification from the pulldown
    if (event.target.closest('.notificationItem')) {
        event.preventDefault();
        const parentDiv = event.target.closest('.notificationItem');
        const currentStatus = parentDiv.dataset.status;
        const nData = new FormData();
        nData.set('appPlatform', 'web');
        nData.set('method', 'notificationStatus');
        nData.set('notificationStatus', 2);
        nData.set('notificationID', parentDiv.dataset.notificationid);
        const target = parentDiv.dataset.target;
        fetch(GATEWAY_DOMAIN + '/userActions/', {
            method: 'POST',
            credentials: 'include',
            body: nData
        })
            .then((response) => {
                return response.json();
            })
            .then(() => {
                if (currentStatus == 1) {
                    parentDiv.dataset.status = 2;
                    deincrementNotificationCount();
                    const unreadDot = parentDiv.querySelector('.unreadDot');
                    if (unreadDot) {
                        unreadDot.classList.remove('unreadDot');
                        unreadDot.classList.add('emptyDot');
                    }
                }
                if (target != '') {
                    window.location.href = target;
                }
            });
    }

    // The "Create New List" link from various places was clicked, alter the modal depending on if there is a title attached to creating the link
    if (event.target.matches('.createListLink')) {
        document.getElementById('listTitle').value = '';
        const createListFormAlert = document.getElementById('createListFormAlert');
        createListFormAlert.classList.remove('d-block');
        createListFormAlert.classList.add('d-none');
        if (event.target.dataset.combinedtitleid) {
            globalCombinedTitleID = event.target.dataset.combinedtitleid;
        }
        if (event.target.dataset.titlename) {
            document.getElementById('createListAddTitle').innerHTML = '<input class="form-check-input" type="checkbox" value="1" id="addTitle" checked><label class="form-check-label" for="addTitle">Add <i><b>' + event.target.dataset.titlename + '</b></i> to this list?</label>';
        }
    }

    // A friend request accepted button was pressed in a notification div
    if (event.target.matches('.friendRequestAccept')) {
        event.preventDefault();
        const friendData = new FormData();
        friendData.set('appPlatform', 'web');
        friendData.set('method', 'acceptFriendRequest');
        friendData.set('friendCombinedUserID', event.target.dataset.friendcombineduserid ? event.target.dataset.friendcombineduserid : '');
        const notificationID = event.target.dataset.notificationid;
        fetch(GATEWAY_DOMAIN + '/userActions/', {
            method: 'POST',
            credentials: 'include',
            body: friendData
        })
            .then((response) => {
                return response.json();
            })
            .then(() => {
                // Delete the notification from the dropdown
                if (notificationID) {
                    const notificationItems = document.querySelectorAll('.notificationItem');
                    notificationItems.forEach((notificationItem) => {
                        if (notificationItem.dataset.notificationid == notificationID) {
                            notificationItem.remove();
                        }
                    });
                }
                deincrementNotificationCount();
                showToast('Friend request accepted!');
            });
    }

    // A friend request declined button was pressed in a notification div
    if (event.target.matches('.friendRequestDecline')) {
        event.preventDefault();
        const friendData = new FormData();
        friendData.set('appPlatform', 'web');
        friendData.set('method', 'declineFriendRequest');
        friendData.set('friendCombinedUserID', event.target.dataset.friendcombineduserid ? event.target.dataset.friendcombineduserid : '');
        const notificationID = event.target.dataset.notificationid;
        fetch(GATEWAY_DOMAIN + '/userActions/', {
            method: 'POST',
            credentials: 'include',
            body: friendData
        })
            .then((response) => {
                return response.json();
            })
            .then(() => {
                // Delete the notification from the dropdown
                if (notificationID) {
                    const notificationItems = document.querySelectorAll('.notificationItem');
                    notificationItems.forEach((notificationItem) => {
                        if (notificationItem.dataset.notificationid == notificationID) {
                            notificationItem.remove();
                        }
                    });
                }
                deincrementNotificationCount();
                showToast('Friend request declined!');
            });
    }

    // A new list was created from the modal. We need to do different things based on where the modal was spawned
    if (event.target.id == 'submitNewList') {
        event.preventDefault();
        const submitNewListButton = document.getElementById('submitNewList');
        submitNewListButton.disabled = true;
        const listData = new FormData();
        listData.set('appPlatform', 'web');

        const addTitle = document.getElementById('addTitle') && document.getElementById('addTitle').checked ? 1 : 0;
        const makeListPublic = document.getElementById('makeListPublic') && document.getElementById('makeListPublic').checked ? 1 : 0;
        listData.set('method', 'createList');
        listData.set('makeListPublic', makeListPublic);
        listData.set('addTitle', addTitle);
        listData.set('combinedTitleID', globalCombinedTitleID ? globalCombinedTitleID : '');
        listData.set('listTitle', document.getElementById('listTitle').value);
        fetch(GATEWAY_DOMAIN + '/userActions/', {
            method: 'POST',
            credentials: 'include',
            body: listData
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                submitNewListButton.disabled = false;
                const createListFormAlert = document.getElementById('createListFormAlert');
                createListFormAlert.classList.add('d-block');
                createListFormAlert.classList.remove('d-none');
                // Only for title pages, find all the add to list buttons and rebuild them with this new list
                const addToListsButtons = document.querySelectorAll('.add-to-lists');
                if (data.success) {
                    createListFormAlert.innerHTML = 'List created!';
                    createListFormAlert.classList.add('alert-success');
                    createListFormAlert.classList.remove('alert-danger');
                    if (globalCombinedTitleID) {
                        addToListsButtons.forEach(async (addToListsButton) => {
                            addToListsButton.outerHTML = await buildWatchlistButton({ combinedTitleID: globalCombinedTitleID }, '', false, data.listID, 'add');
                        });
                    }
                    // If we added a title, check to see if this is dashboard/power search, and update the incustomlists of any poster tiles with this title
                    if (addTitle && globalCombinedTitleID) {
                        const posterTiles = document.querySelectorAll('.pts');
                        posterTiles.forEach((posterTile) => {
                            if (posterTile.dataset.ctid == globalCombinedTitleID) {
                                var inCustomLists = JSON.parse(posterTile.dataset.incustomlists);
                                inCustomLists.push(parseInt(data.listID));
                                posterTile.dataset.incustomlists = JSON.stringify(inCustomLists);
                            }
                        });
                        setListManagerButtonIcon(globalCombinedTitleID);
                    }
                    // Only on the "My Custom Lists" page, add the list to the table
                    const customListsTable = document.getElementById('customListsTableBody');
                    if (customListsTable) {
                        const isPublic = parseInt(data.isPublic) == 1 ? 'Yes' : 'No';
                        customListsTable.innerHTML = '<tr id="tableRow' + data.listID + '"><td><a href="/lists/' + data.slug + '/" class="teal">' + data.listTitle + '</a></td><td>' + isPublic + '</td><td>Just now</td><td>0</td><td> <img src="https://cdn.watchmode.com/misc_images/icons/redX.png" data-listid="' + data.listID + '" class="deleteList" style=" width: 14px; height: 14px; cursor: pointer; margin-left: 5px;" id="delete' + data.listID + '"></td></tr>' + customListsTable.innerHTML;
                    }
                    // If the list was created from a posterListManager Modal view, we need to add the list to the list buttons in the modal
                    const posterListManagerListsContainer = document.getElementById('posterListManagerListsContainer');
                    if (posterListManagerListsContainer) {
                        const buttonClass = document.getElementById('addTitle').checked ? 'btn-primary' : 'btn-secondary';
                        // Get rid of the "no lists yet" message if this is the user's first list
                        if (posterListManagerListsContainer.innerHTML.includes('have any custom lists yet')) {
                            posterListManagerListsContainer.innerHTML = '<button style="white-space:nowrap;" class="btn posterListManagerCustomList me-1 ' + buttonClass + '" data-listid="' + data.listID + '">' + data.listTitle + '</button>';
                        } else {
                            posterListManagerListsContainer.innerHTML = '<button style="white-space:nowrap;" class="btn posterListManagerCustomList me-1 ' + buttonClass + '" data-listid="' + data.listID + '">' + data.listTitle + '</button>' + posterListManagerListsContainer.innerHTML;
                        }
                    }
                } else {
                    createListFormAlert.innerHTML = data.errorMessage;
                    createListFormAlert.classList.add('alert-danger');
                    createListFormAlert.classList.remove('alert-success');
                }
            });
    }

    // Add or remove a title to a list, from the watchlist dropdown (ususally on /title pages)
    if (event.target.matches('.watchlistDropdown')) {
        event.preventDefault();
        globalCombinedTitleID = event.target.dataset.combinedtitleid;

        const listData = new FormData();
        listData.set('appPlatform', 'web');
        const listID = event.target.dataset.listid;
        const type = event.target.dataset.type;
        if (listID === 'watchlist') {
            listData.set('method', 'watchlistStatus');
            const status = type === 'add' ? 1 : 0;
            listData.set('watchlistStatus', status);
        } else {
            listData.set('method', 'listAddRemove');
            listData.set('type', type);
            listData.set('listID', listID);
        }
        listData.set('combinedTitleID', globalCombinedTitleID ? globalCombinedTitleID : '');
        fetch(GATEWAY_DOMAIN + '/userActions/', {
            method: 'POST',
            credentials: 'include',
            body: listData
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    const addToListsButtons = document.querySelectorAll('.add-to-lists');
                    addToListsButtons.forEach(async (addToListsButton) => {
                        var combinedTitleID2 = addToListsButton.dataset.combinedtitleid;
                        if (globalCombinedTitleID == combinedTitleID2 && addToListsButton.id.includes('addToLists')) {
                            addToListsButton.outerHTML = await buildWatchlistButton({ combinedTitleID: globalCombinedTitleID }, '', false, listID, type);
                        }
                    });
                }
            });
    }

    // Change the status of a title on the server, after an option in a dropdown was selected (on title pages, coming-soon, Watch next)
    if (event.target.matches('.statusDropdown')) {
        event.preventDefault();
        globalCombinedTitleID = event.target.dataset.combinedtitleid;

        const status = event.target.dataset.status ? event.target.dataset.status : '';
        const statusData = new FormData();
        statusData.set('appPlatform', 'web');

        statusData.set('method', 'titleStatus');
        statusData.set('status', status);
        statusData.set('ratingSource', 2);
        statusData.set('combinedTitleID', globalCombinedTitleID ? globalCombinedTitleID : '');
        fetch(GATEWAY_DOMAIN + '/userActions/', {
            method: 'POST',
            credentials: 'include',
            body: statusData
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    const statusButtons = document.querySelectorAll('.statusButton');
                    const statusTexts = document.querySelectorAll('.statusText');
                    statusButtons.forEach((statusButton) => {
                        if (statusButton.dataset.combinedtitleid != globalCombinedTitleID) {
                            return;
                        }
                        let color = '#FFFFFF';
                        let bgColor = '#d0d0d0';
                        if (status == 'statusfavorite') {
                            bgColor = '#D4AF37';
                        } else if (status == 'statuslovedit') {
                            bgColor = '#159500';
                        } else if (status == 'statuslikedit') {
                            bgColor = '#00cc65';
                        } else if (status == 'statusok') {
                            bgColor = '#c77d1d';
                        } else if (status == 'statusdidntlike') {
                            bgColor = '#6c3d00';
                        } else if (status == 'statushate') {
                            bgColor = '#aa0000';
                        } else if (status == 'statushsinterested') {
                            bgColor = '#159500';
                        } else if (status == 'statushsdontthinkso') {
                            bgColor = '#d0d0d0';
                        } else if (status == 'statushsno') {
                            bgColor = '#aa0000';
                        }
                        statusButton.style.backgroundColor = bgColor;
                        statusButton.style.color = color;
                    });
                    statusTexts.forEach((statusText) => {
                        if (statusText.dataset.combinedtitleid != globalCombinedTitleID) {
                            return;
                        }
                        if (status == 'statusfavorite') {
                            statusText.innerHTML = '<img src="' + WATCHMODE_CDN1 + '/icons/trophy.svg" alt="All time" width="18" height="18" class="me-1">All Time';
                        } else if (status == 'statuslovedit') {
                            statusText.innerHTML = '<img src="' + WATCHMODE_CDN1 + '/icons/heart.svg" alt="Loved it" width="18" height="18" class="me-1">Loved It';
                        } else if (status == 'statuslikedit') {
                            statusText.innerHTML = '<img src="' + WATCHMODE_CDN1 + '/icons/hand-thumbs-up.svg" alt="Liked it" width="18" height="18" class="me-1">Liked It';
                        } else if (status == 'statusok') {
                            statusText.innerHTML = '<img src="' + WATCHMODE_CDN1 + '/icons/emoji-expressionless.svg" alt="Just ok" width="18" height="18" class="me-1">Just O.K.';
                        } else if (status == 'statusdidntlike') {
                            statusText.innerHTML = '<img src="' + WATCHMODE_CDN1 + '/icons/emoji-frown.svg" alt="Disliked" width="18" height="18" class="me-1">Disliked';
                        } else if (status == 'statushate') {
                            statusText.innerHTML = '<img src="' + WATCHMODE_CDN1 + '/icons/hand-thumbs-down.svg" alt="Hated it" width="18" height="18" class="me-1">Hated It';
                        } else if (status == 'statushsinterested') {
                            statusText.innerHTML = 'Want to See';
                        } else if (status == 'statushsdontthinkso') {
                            statusText.innerHTML = 'Not Seen';
                        } else if (status == 'statushsno') {
                            statusText.innerHTML = 'No Interest';
                        }
                    });
                }
            });
    }

    // Handle the press of a region change button
    if (event.target.closest('.regionChooserCountry')) {
        hideModal('regionChooserModal');
        const regionButton = event.target.closest('.regionChooserCountry');
        window.location.href = '/misc/changeRegion/?didRedirect=1&region=' + regionButton.dataset.region + '&returnURL=' + encodeURIComponent(window.location.href);
    }

    // Handle when a div in the search results is pressed, find the combinedID and the result type to figure out where to send the user
    if (event.target.matches('.typeaheadSearchResultRow') || event.target.matches('.typeaheadSearchResultPoster') || event.target.matches('.typeaheadSearchResultText')) {
        if (event.target.dataset.clicktype == 1) {
            // This is a search result, a click goes to the detail page
            if (event.target.dataset.resulttype == 1) {
                window.location.href = '/' + event.target.dataset.slugtypefolder + '/' + event.target.dataset.slug + '/';
            } else if (event.target.dataset.resulttype == 2) {
                window.location.href = '/people/' + event.target.dataset.slug + '/';
            }
        } else if (event.target.dataset.clicktype == 2) {
            // This is a watchlist click, we need to save the title to the watchlist on the server, and refresh the page
            const data = new FormData();
            data.set('appPlatform', 'web');
            if (listID === 'my-watchlist') {
                data.set('method', 'watchlistStatus');
                data.set('watchlistStatus', 1);
            } else {
                data.set('type', 'add');
                data.set('method', 'listAddRemove');
                if (listID) data.set('listID', listID);
            }
            data.set('combinedTitleID', event.target.dataset.combinedid);

            fetch(GATEWAY_DOMAIN + '/userActions/', {
                method: 'POST',
                credentials: 'include',
                body: data
            })
                .then((response) => {
                    return response.json();
                })
                .then(() => {
                    const encodedMessage = window.btoa('1|This title has been added your list!');
                    window.location.replace(returnUrl + '?message=' + encodedMessage);
                });
        } else if (event.target.dataset.clicktype == 3) {
            // Content admin add title
            const quickSearchTypeaheadContentAdmin = document.getElementById('quickSearchTypeaheadContentAdmin');
            quickSearchTypeaheadContentAdmin.value = window.unescape(event.target.dataset.name);
            quickSearchTypeaheadContentAdmin.dataset.combinedid = event.target.dataset.combinedid;
        }
    }
});

window.addEventListener('resize', () => {
    const w = document.documentElement.clientWidth;
    const hiddenSearchIcon = document.getElementById('hiddenSearchIcon');
    const notificationDropdown = document.getElementById('notificationDropdown');
    // On smaller screens show the hidden search icon and make the notification dropdown drop right to prevent it going off screen
    if (w < 1385) {
        if (hiddenSearchIcon !== null) {
            hiddenSearchIcon.classList.add('d-flex');
            hiddenSearchIcon.classList.remove('d-none');
        }
        if (isLoggedIn && notificationDropdown !== null) {
            notificationDropdown.classList.remove('dropdown-menu-right');
        }
    } else {
        if (hiddenSearchIcon !== null) {
            hiddenSearchIcon.classList.remove('d-flex');
            hiddenSearchIcon.classList.add('d-none');
        }
        if (isLoggedIn && notificationDropdown !== null) {
            notificationDropdown.classList.add('dropdown-menu-right');
        }
    }
});

window.addEventListener('scroll', () => {
    // Shrink the navbar on scroll
    const navBar = document.querySelector('.navbar');
    const fixedTop = document.querySelector('.fixed-top');
    if (!navbarHidden) {
        if (navBar && fixedTop) {
            if (window.scrollY >= 40) {
                navBar.classList.add('navbar-hide');
                fixedTop.classList.add('top-nav-collapse');
            } else {
                navBar.classList.remove('navbar-hide');
                fixedTop.classList.remove('top-nav-collapse');
            }
        }
    }

    // Show the back to top button
    const backToTop = document.querySelector('.back-to-top');
    if (backToTop) {
        if (window.scrollY >= 700) {
            backToTop.classList.remove('d-none');
            backToTop.classList.add('d-block');
        } else {
            backToTop.classList.remove('d-block');
            backToTop.classList.add('d-none');
        }
    }
});

// handle when a notification was pressed in the foreground
/* jshint ignore:start */
self.addEventListener('notificationclick', (event) => {
    event.preventDefault();
    const link = event.notification.link !== null && event.notification.link != '' ? decodeURIComponent(event.notification.link) : '';
    event.notification.close(); // Android needs explicit close.
    event.waitUntil(
        clients
            .matchAll({
                type: 'window'
            })
            .then((windowClients) => {
                // Check if there is already a window/tab open with the target URL
                for (var i = 0; i < windowClients.length; i++) {
                    const client = windowClients[i];
                    // If so, just focus it.
                    if (client.url === link && 'focus' in client) {
                        return client.focus();
                    }
                }
                // If not, then open the target URL in a new window/tab.
                if (clients.openWindow && link != null && link != '') {
                    return clients.openWindow(link);
                }
            })
    );
});
/* jshint ignore:end */

document.addEventListener('submit', (e) => {
    if (e.target.id == 'quickSearchNav') {
        e.preventDefault();
        const quickSearchInput = document.getElementById('quickSearchTypeaheadNav');
        if (quickSearchInput.value) {
            let searchTerm = quickSearchInput.value.replace(/'/g, '%27');
            searchTerm = encodeURIComponent(searchTerm);
            window.location.replace('/search/' + searchTerm + '/');
        }
    }
    if (e.target.id == 'quickSearchIndex') {
        e.preventDefault();
        const quickSearchInput = document.getElementById('quickSearchTypeaheadIndex');
        if (quickSearchInput.value) {
            let searchTerm = quickSearchInput.value.replace(/'/g, '%27');
            searchTerm = encodeURIComponent(searchTerm);
            window.location.replace('/search/' + searchTerm + '/');
        }
    }
});

// Query the server for the quickSearch results for this term
function getSearchResults(searchTerm, type, callback) {
    const data = new FormData();
    data.set('appPlatform', 'web');
    data.set('q', searchTerm);
    data.set('type', type);

    fetch(GATEWAY_DOMAIN + '/quickSearch/', {
        method: 'POST',
        credentials: 'include',
        body: data
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            callback(data);
        });
}

// Build the rows for the search results, display in container
function buildSearchResultsHTML(searchTerm, results, resultsContainer, clickType) {
    var resultsHTML = '';
    var bgClass = 'medGreyBg';
    results.results.forEach((el) => {
        bgClass = bgClass == 'medGreyBg' ? 'medGreyBg2' : 'medGreyBg';
        resultsHTML += '<div class="w-100 d-inline-flex align-content-center align-items-center ' + bgClass + ' typeaheadSearchResultRow" data-name="' + window.escape(el.name) + '" data-type="' + el.type + '" data-combinedid="' + el.combinedID + '" data-slug="' + el.slug + '" data-slugtypefolder="' + el.slugTypeFolder + '" data-resulttype="' + el.resultType + '" data-clicktype="' + clickType + '" style="cursor:pointer;"><div><img src="' + el.thumbnailURL + '" class="shrinkingPoster typeaheadSearchResultPoster" data-name="' + window.escape(el.name) + '" data-combinedid="' + el.combinedID + '" data-slug="' + el.slug + '" data-slugtypefolder="' + el.slugTypeFolder + '" data-type="' + el.type + '" data-resulttype="' + el.resultType + '" data-clicktype="' + clickType + '" style="cursor:pointer;"></div><div class="px-2"><h4 data-combinedid="' + el.combinedID + '" data-slug="' + el.slug + '" data-slugtypefolder="' + el.slugTypeFolder + '" data-type="' + el.type + '" data-resulttype="' + el.resultType + '" data-clicktype="' + clickType + '"  data-name="' + window.escape(el.name) + '" class="white typeaheadSearchResultText" style="font-weight: 500 !important;cursor:pointer;">' + el.name + '</h4><div data-name="' + window.escape(el.name) + '" data-combinedid="' + el.combinedID + '" data-slug="' + el.slug + '" data-slugtypefolder="' + el.slugTypeFolder + '" data-type="' + el.type + '"data-resulttype="' + el.resultType + '" data-clicktype="' + clickType + '" class="lightGrey typeaheadSearchResultText" style="cursor:pointer;">' + el.subtext + '</div></div></div>';
    });
    if (resultsHTML == '') {
        // Show a "no results" message
        resultsHTML += '<div class="w-100 p-4 ' + bgClass + '">We can\'t seem to find any results for: <i>' + searchTerm + '</i></div>';
    }

    resultsContainer.classList.remove('d-none');
    resultsContainer.classList.add('d-block');
    resultsContainer.innerHTML = resultsHTML;
}

function toggleHiddenSearchIcon() {
    const w = document.documentElement.clientWidth;
    const hiddenSearchIcon = document.getElementById('hiddenSearchIcon');
    const notificationDropdown = document.getElementById('notificationDropdown');
    // On smaller screens show the hidden search icon and make the notification dropdown drop right to prevent it going off screen
    if (w < 1385) {
        if (hiddenSearchIcon !== null) {
            hiddenSearchIcon.classList.add('d-flex');
            hiddenSearchIcon.classList.remove('d-none');
        }
        if (isLoggedIn && notificationDropdown !== null) {
            notificationDropdown.classList.remove('dropdown-menu-right');
        }
    } else {
        if (hiddenSearchIcon !== null) {
            hiddenSearchIcon.classList.remove('d-flex');
            hiddenSearchIcon.classList.add('d-none');
        }
        if (isLoggedIn && notificationDropdown !== null) {
            notificationDropdown.classList.add('dropdown-menu-right');
        }
    }
}

function setupSearchInputs() {
    const searchInputs = document.querySelectorAll('.jsTypeaheadInput');
    [].forEach.call(searchInputs, (searchInput) => {
        var resultsContainer = document.querySelector('#' + searchInput.dataset.container);
        var timeout = null;
        searchInput.addEventListener('input', function () {
            clearTimeout(timeout);
            var searchTerm = this.value;
            if (searchTerm.length < 3) {
                resultsContainer.classList.add('d-none');
                resultsContainer.classList.remove('d-block');
            }
            // wait a half a second to make sure they finish typing
            timeout = setTimeout(() => {
                if (searchTerm.length > 1) {
                    getSearchResults(searchTerm, searchInput.dataset.type, (results) => {
                        buildSearchResultsHTML(searchTerm, results, resultsContainer, searchInput.dataset.clicktype);
                    });
                } else {
                    resultsContainer.classList.add('d-none');
                    resultsContainer.classList.remove('d-block');
                }
            }, 500);
        });
    });
}

function deincrementNotificationCount() {
    // Lower the notification count by 1
    const notificationCountBadge = document.getElementById('notificationCountBadge');
    let notificationCount = parseInt(notificationCountBadge.innerText);
    if (notificationCount > 0) {
        notificationCount = notificationCount - 1;
        if (notificationCount > 0) {
            notificationCountBadge.innerText = notificationCount;
        } else {
            notificationCountBadge.classList.add('d-none');
        }
    }
}
