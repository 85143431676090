'use strict';
import Modal from 'bootstrap/js/dist/modal';

export const hideModal = (elementId) => {
    const modalElement = document.getElementById(elementId);
    const modal = Modal.getInstance(modalElement);
    if (modal) {
        modal.hide();
    }
}

export const showModal = (elementId) => {
    const modalElement = document.getElementById(elementId);
    const modal = new Modal(modalElement);
    modal.show();
}

export const showSpinner = () => {
    const spinner = document.querySelector('.spinner-wrapper');
    spinner.style.zIndex = '999';
    if (spinner) {
        spinner.classList.add('d-block');
        spinner.classList.remove('d-none');
        // Move the spinner below other content after it fades out
    }
}

export const hideSpinner = () => {
    const spinner = document.querySelector('.spinner-wrapper');
    if (spinner) {
        spinner.classList.remove('d-block');
        spinner.classList.add('d-none');
        // Move the spinner below other content after it fades out
        setTimeout(() => {
            spinner.style.zIndex = '-1';
        }, 400);
    }
}

export const showToast = (message, position = 'center', autoHide = true, backgroundColor = '#333333', textColor = '#FFFFFF')=>  {
    const x = document.getElementById('toast');
    x.style.left = '50%';
    x.style.bottom = position == 'center' ? '50%' : '10%';
    x.innerText = message;
    x.className = 'show';
    x.style.backgroundColor = backgroundColor;
    x.style.color = textColor;
    if (autoHide) {
        setTimeout(() => {
            x.className = x.className.replace('show', '');
        }, 3000);
    }
}

// When a title is toggle in a list or my shows, update the dashboard icon to reflect the change for this title
export const setListManagerButtonIcon = (combinedTitleID) => {
    const posterTiles = document.querySelectorAll('.pts');
    posterTiles.forEach((posterTile) => {
        if (posterTile.dataset.ctid == combinedTitleID) {
            const lmlIcon = posterTile.querySelector('.lmlIcon');
            if (posterTile.dataset.myshowsstatus == 1) {
                lmlIcon.classList.add('mt-1');
                lmlIcon.src = 'https://cdn.watchmode.com/icons/tv-white.svg';
                lmlIcon.width = 24;
                lmlIcon.height = 24;
            } else {
                lmlIcon.classList.remove('mt-1');
                lmlIcon.width = 34;
                lmlIcon.height = 34;
                if (posterTile.dataset.watchliststatus == 1 || posterTile.dataset.incustomlists != '[]') {
                    lmlIcon.src = 'https://cdn.watchmode.com/icons/check.svg';
                } else {
                    lmlIcon.src = 'https://cdn.watchmode.com/icons/plus.svg';
                }
            }
            const lmlCountBadge = posterTile.querySelector('.tileWatchlistCount');
            const inCustomLists = JSON.parse(posterTile.dataset.incustomlists);
            const watchlistCount = inCustomLists.length + parseInt(posterTile.dataset.watchliststatus);
            if (watchlistCount > 1) {
                lmlCountBadge.innerHTML = watchlistCount;
                lmlCountBadge.classList.add('d-block');
                lmlCountBadge.classList.remove('d-none');
            } else {
                lmlCountBadge.classList.add('d-none');
                lmlCountBadge.classList.remove('d-block');
            }
            const lmlContainer = posterTile.querySelector('.tileWatchlistButton');
            if (posterTile.dataset.myshowsstatus == 1) {
                lmlContainer.classList.remove('watchlistBgGreen', 'watchlistBgBlack');
                lmlContainer.classList.add('watchlistBgOrange');
            } else {
                lmlContainer.classList.remove('watchlistBgOrange');
                if (posterTile.dataset.watchliststatus == 1 || posterTile.dataset.incustomlists != '[]') {
                    lmlContainer.classList.add('watchlistBgGreen');
                    lmlContainer.classList.remove('watchlistBgBlack');
                } else {
                    lmlContainer.classList.add('watchlistBgBlack');
                    lmlContainer.classList.remove('watchlistBgGreen');
                }
            }
        }
    });
}

export const removeFadeOut = (el)=>  {
    if (!el.classList.contains('fadeOutOneSecond')) {
        el.classList.add('fadeOutOneSecond');
    }

    el.style.opacity = 0;
    setTimeout(() => {
        el.parentNode.removeChild(el);
    }, 1000);
}

export const lazyLoadIntersectionObserver = ()=>  {
    const lazyloadImages = document.querySelectorAll('.lazy');
    if ('IntersectionObserver' in window) {
        const imageObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const image = entry.target;
                    image.src = image.dataset.src;
                    image.classList.remove('lazy');
                    image.classList.remove('lazyNotViewable');
                    image.classList.add('lazyViewable');
                    imageObserver.unobserve(image);
                }
            });
        });
        lazyloadImages.forEach((image) => {
            if (!image.dataset.observed) {
                imageObserver.observe(image);
                image.dataset.observed = 1;
            }
        });
    } else {
        // There's no intersection observer, just change the src's back to the real URL
        lazyloadImages.forEach((image) => {
            image.src = image.dataset.src;
            image.classList.remove('lazy');
            image.classList.remove('lazyNotViewable');
            image.classList.add('lazyViewable');
        });
    }
}

let carouselPushed = 0;
const mouseUpCarouselListener = function () {
    if (carouselPushed) {
        setTimeout(() => {
            const links = document.querySelectorAll('.tpl');
            enabletpls(links);
        }, 100);
    }
    carouselPushed = 0;
};

// Monitor the drag state of each carousel, to enable/disable the poster links accordingly
export const monitorCarouselDrags = () => {
    const horizontalOverflowScrollDivs = document.querySelectorAll('.horizontalOverflowScroll');
    document.removeEventListener('mouseup', mouseUpCarouselListener);
    document.addEventListener('mouseup', mouseUpCarouselListener);
    horizontalOverflowScrollDivs.forEach((horizontalOverflowScrollDiv) => {
        // Make sure we havent setup monitoring on this yet, to prevent adding monitoring over and over on dashboard lazy load
        if (!horizontalOverflowScrollDiv.hasAttribute('data-monitored')) {
            if (horizontalOverflowScrollDiv.dataset.fetched == 1 || !horizontalOverflowScrollDiv.classList.contains('posterCarousel')) {
                // eslint-disable-next-line no-unused-vars
                let lastClientX, lastClientY, newScrollX, newScrollY;
                horizontalOverflowScrollDiv.addEventListener('mousemove', (e) => {
                    if (carouselPushed) {
                        const links = horizontalOverflowScrollDiv.querySelectorAll('.tpl');
                        disablePosterTileLinks(links);
                        horizontalOverflowScrollDiv.scrollLeft -= newScrollX = -lastClientX + (lastClientX = e.clientX);
                        horizontalOverflowScrollDiv.scrollTop -= newScrollY = -lastClientY + (lastClientY = e.clientY);
                    }
                });
                horizontalOverflowScrollDiv.addEventListener('mousedown', (e) => {
                    carouselPushed = 1;
                    lastClientX = e.clientX;
                    lastClientY = e.clientY;
                    e.preventDefault();
                });
                horizontalOverflowScrollDiv.dataset.monitored = 1;
            }
        }
    });
}

export const disablePosterTileLinks = (links) => {
    links.forEach((link) => {
        link.classList.add('disabledLink');
    });
}

export const enabletpls = (links) => {
    links.forEach((link) => {
        link.classList.remove('disabledLink');
    });
}