import { WATCHMODE_CDN1, GATEWAY_DOMAIN } from '../constants';
import { htmlspecialchars } from './htmlSpecialChars';
/* global isLoggedIn */

export const buildWatchlistButton = async (title, idAppend = '', includeWrapper = true, listID = null, listAction = null) => {
    const { combinedTitleID } = title;

    const titleEncoded = title.title ? htmlspecialchars(title.title) : '';
    const customLists = await fetchCustomLists();
    const listsToAddTo = [];
    const listsToRemoveFrom = [];

    const myWatchlistObject = {
        id: 'watchlist',
        name: 'My Watchlist'
    };

    if (title.watchlistStatus || (listID === 'watchlist' && listAction === 'add')) {
        listsToRemoveFrom.push(myWatchlistObject);
    } else {
        listsToAddTo.push(myWatchlistObject);
    }

    if (isLoggedIn) {
        for (const list of customLists) {
            if ((title && title.inCustomLists && title.inCustomLists.includes(list.id)) || (listID && +listID === list.id && listAction === 'add')) {
                listsToRemoveFrom.push(list);
            } else {
                listsToAddTo.push(list);
            }
        }
    }

    const modalName = isLoggedIn ? 'createListModal' : 'needAccountModal';
    const counterInsert = listsToRemoveFrom.length > 0 ? ` (${listsToRemoveFrom.length})` : '';

    let listsToAddHtml = '';

    for (const list of listsToAddTo) {
        const dropdownClass = isLoggedIn ? 'watchlistDropdown' : '';
        const modalHtml = !isLoggedIn ? `data-bs-toggle="modal" data-bs-target="#${modalName}"` : '';
        const isWatchlist = list.id === 'watchlist' ? 1 : 0;
        listsToAddHtml += `
            <a class="dropdown-item textRegular ${dropdownClass}" href="#" ${modalHtml} id="watchlist_add_${list.id}_${combinedTitleID}" data-listid="${list.id}" data-type="add" data-iswatchlist="${isWatchlist}" data-combinedtitleid="${combinedTitleID}"><img src="${WATCHMODE_CDN1}/icons/plus-square-fill-teal.svg" alt="Add to list" width="16" height="16" class="me-1">${list.name}</a>
        `;
    }

    let listsToRemoveHtml = listsToRemoveFrom.length > 0 ? '<div class="dropdown-divider"></div><h6 class="dropdown-header">Remove Title From:</h6>' : '';

    for (const list of listsToRemoveFrom) {
        const isWatchlist = list.id === 'watchlist' ? 1 : 0;
        listsToRemoveHtml += `
        <a class="dropdown-item textRegular watchlistDropdown" href="#" id="watchlist_remove_${list.id}_${combinedTitleID}${idAppend}" data-listid="${list.id}" data-type="remove" data-iswatchlist="${isWatchlist}" data-combinedtitleid="${combinedTitleID}"><img src="${WATCHMODE_CDN1}/misc_images/icons/redX.png" style="width: 17px; height: 17px; margin-right: 5px;" alt="Remove from list" class="me-1">${list.name}</a>
        `;
    }

    const iconHtml = listsToRemoveFrom.length ? `<img src="${WATCHMODE_CDN1}/misc_images/icons/greenCheck16x16Centered.png" alt="On Watchlists" style="width: 17px; height: 17px; margin-right: 5px;">` : `<img src="${WATCHMODE_CDN1}/icons/plus-square-fill-teal.svg" alt="Add to watchlist" width="16" height="16" class="me-1">`;

    const wrapperInsertTop = includeWrapper ? ` <div class="dropdown me-1" id="addToListsDiv-${combinedTitleID}${idAppend}">` : '';
    const wrapperInsertBottom = includeWrapper ? '</div>' : '';
    const button = `${wrapperInsertTop}
        <button type="button" class="btn dropdown-toggle textRegular lightGreyBg2 offWhite add-to-lists" data-titlename="${titleEncoded}" id="addToLists-${combinedTitleID}${idAppend}" data-bs-toggle="dropdown" aria-expanded="false" data-combinedtitleid="${combinedTitleID}">
            ${iconHtml}
            Watchlists${counterInsert}
        </button>
        <div class="dropdown-menu">
            <h6 class="dropdown-header">Add Title To:</h6>
            ${listsToAddHtml}
            <a href="#" class="dropdown-item textRegular createListLink textRegular" data-bs-toggle="modal" data-bs-target="#${modalName}" id="createListLink-${combinedTitleID}${idAppend}" data-combinedtitleid="${combinedTitleID}" data-titlename="${titleEncoded}">
                <img src="${WATCHMODE_CDN1}/icons/plus-square-fill-teal.svg" alt="Add to watchlist" width="16" height="16" class="me-1">Create New List</a>
            ${listsToRemoveHtml}
        </div>
    ${wrapperInsertBottom}`;

    return button;
};

async function fetchCustomLists() {
    try {
        const body = new FormData();
        body.set('appPlatform', 'web');
        const response = await fetch(GATEWAY_DOMAIN + '/fetchCustomLists/', {
            method: 'POST',
            credentials: 'include',
            body
        });

        if (!response.ok) return [];

        const data = await response.json();
        return data.lists;
    } catch (error) {
        console.error('Error fetching custom lists:', error);
        return [];
    }
}
